import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardShadow = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 500px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2));
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

const Image = styled(Img)`
  width: 45%;
  min-width: 75px;
`;

const Divider = styled.hr`
  width: 50px;
  margin: 3em 0 2em;
`;

const ProductName = styled.p`
  font-size: 1.2em;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

const ProductCard = ({ image, productName, ...rest }) => (
  <CardShadow {...rest}>
    <Card>
      <Image
        imgStyle={{
          objectFit: 'contain',
        }}
        fluid={image.image}
        alt={image.alt}
      />

      <Divider />

      <ProductName>{productName}</ProductName>
    </Card>
  </CardShadow>
);

ProductCard.propTypes = {
  image: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
};

export default styled(ProductCard)``;
