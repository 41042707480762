import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Banner from '../components/banner/banner';
import VerticalText from '../components/verticalText/verticalText';
import FormattedHtml from '../components/formattedHtml/formattedHtml';
import Container from '../components/container/container';
import SectionBenefits from '../sections/benefits/sectionBenefits';
import Footer from '../components/footer/footer';
import Heading from '../components/heading/heading';
import EUBanner from '../components/EUBanner/EUBanner';
import { mobileFirstMedia as media } from '../config/theme';

const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  position: relative;

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: 20% 20% 1fr 1fr;
  }
`;

const Subgrid = styled.div`
  display: grid;
  grid-gap: 40px;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    align-self: flex-start;
  }
`;

const HeadingArea = styled.div``;

const ContentArea = styled.div`
  /* @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  } */
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Image1Area = styled.div`
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    grid-column: 2 / 3;
    width: 150%;
  }

  @media (${media.m}) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
`;

const Image1 = styled(Image)`
  clip-path: polygon(
    var(--cropSize) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 var(--cropSize)
  );
`;

const Image2Area = styled.div`
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    margin: 40% 0 calc(var(--cropSize) * 0.8);
  }

  @media (${media.m}) {
    grid-column: 4 / 5;
    grid-row: 1 / 3;
  }
`;

const Image2 = styled(Image)`
  @media (${media.xs}) {
    clip-path: polygon(
      100% 0,
      100% calc(100% - var(--cropSize)),
      calc(100% - var(--cropSize)) 100%,
      0 100%,
      0 0
    );
  }
`;

const Image3Area = styled.div`
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    grid-column: 2 / 3;
  }

  @media (${media.m}) {
    grid-column: 3 / 4;
  }
`;

const Image3 = styled(Image)`
  clip-path: polygon(
    100% 0,
    100% calc(100% - calc(var(--cropSize) * 0.8)),
    calc(100% - calc(var(--cropSize) * 0.8)) 100%,
    0 100%,
    0 0
  );
`;

const AboutPage = ({
  data: {
    prismicStronaONas: {
      data: {
        baner,
        tytul_sekcji,
        naglowek_sekcji,
        tresc_sekcji,
        zdjecie_1,
        zdjecie_2,
        zdjecie_3,
        body_meta,
      },
    },
  },
}) => {
  const seoData = body_meta && body_meta.find(el => el.slice_type === 'seo');

  return (
    <>
      <SEO
        title={seoData.primary.tytul}
        description={seoData.primary.opis}
        url="/o-nas/"
      />

      <Banner
        title="O NAS"
        image={{ alt: baner.alt, image: baner.localFile.childImageSharp.fluid }}
      />

      <Container
        css={`
          @media (${media.m}) {
            padding-left: 130px;
          }

          @media (min-width: 1690px) {
            padding-left: 25px;
          }
        `}
      >
        <Grid>
          <VerticalText text="O NAS" />

          <Subgrid>
            <HeadingArea data-aos="fade-up" data-aos-delay="200">
              <Heading
                title={tytul_sekcji.text}
                heading={naglowek_sekcji.html}
              />
            </HeadingArea>

            <ContentArea data-aos="fade-up" data-aos-delay="300">
              <FormattedHtml
                dangerouslySetInnerHTML={{ __html: tresc_sekcji.html }}
              />
            </ContentArea>
          </Subgrid>

          <Image1Area data-aos="fade-down" data-aos-delay="200">
            <Image1
              fluid={zdjecie_1.localFile.childImageSharp.fluid}
              alt={zdjecie_1.alt}
            />
          </Image1Area>

          <Image2Area data-aos="fade-left" data-aos-delay="300">
            <Image2
              fluid={zdjecie_2.localFile.childImageSharp.fluid}
              alt={zdjecie_2.alt}
            />
          </Image2Area>

          <Image3Area data-aos="fade-right" data-aos-delay="300">
            <Image3
              fluid={zdjecie_3.localFile.childImageSharp.fluid}
              alt={zdjecie_3.alt}
            />
          </Image3Area>
        </Grid>
      </Container>

      <SectionBenefits css="margin-top: 80px;" />

      <EUBanner />

      <Footer />
    </>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    prismicStronaONas {
      data {
        baner {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_1 {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_2 {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_3 {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji {
          text
        }
        naglowek_sekcji {
          html
        }
        tresc_sekcji {
          html
        }
        body_meta {
          slice_type
          primary {
            tytul
            opis
          }
        }
      }
    }
  }
`;

export default AboutPage;
