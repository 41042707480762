import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { media } from '../../config/theme';

const Img = styled(GatsbyImage)`
  max-width: 600px;
  max-height: 80px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: -40px;

  ${media.s`
    margin-bottom: 0;
    max-width: calc(100vw - 20px);
  `}
`;

const EUBanner = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);

  return (
    <div>
      <Img fluid={fluid} imgStyle={{ objectFit: 'contain' }} />
    </div>
  );
};

const query = graphql`
  query {
    file(name: { eq: "baner-unii" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default EUBanner;
