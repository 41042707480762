import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { mobileFirstMedia as media } from '../../config/theme';

import ProductCardDefault from '../../components/productCard/productCard';

export const Grid = styled.div`
  display: grid;
  grid-gap: 50px;
  position: relative;
  /* overflow-x: hidden; */

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 550px) minmax(50px, 550px)
      minmax(25px, 1fr);
    padding: 50px 0;
  }
`;

export const HeadingArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
  }

  @media (${media.m}) {
    grid-column: 2 / 4;
  }
`;

export const CardsArea = styled.div`
  display: grid;
  grid-gap: 40px;

  @media (${media.xs}) {
    grid-column: 2 / 4;
    grid-template-columns: 1fr 1fr;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
    grid-column: 2 / 4;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ProductCard = styled(ProductCardDefault)``;

export const BackgroundImageArea = styled.div`
  margin: 0 -25px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    margin: 0;
    grid-column: 1 / 5;
  }

  @media (${media.m}) {
    position: absolute;
    top: 0;
    left: -25px;
    left: 0;
    width: calc(100% + 50px);
    width: 100%;
    height: 100%;
    filter: none;
    z-index: -1;
  }
`;

export const BackgroundImage = styled(GatsbyImage)`
  height: 100%;
  clip-path: polygon(
    var(--cropSize) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 var(--cropSize)
  );
`;
