import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../components/container/container';
import Heading from '../../components/heading/heading';
import VerticalText from '../../components/verticalText/verticalText';

import {
  Grid,
  HeadingArea,
  CardsArea,
  ProductCard,
  BackgroundImageArea,
  BackgroundImage,
} from './styles';

const SectionBenefits = ({ ...rest }) => {
  const {
    prismicStronaGlowna: { data },
  } = useStaticQuery(query);

  const benefitsItems = data.body_zalety.find(
    el => el.slice_type === 'sekcja_zalety'
  ).items;

  return (
    <section {...rest}>
      <Container width={1920}>
        <Grid>
          <HeadingArea css="justify-self: center;" data-aos="fade-in">
            <Heading
              title={data.tytul_sekcji_zalety.text}
              heading={data.naglowek_sekcji_zalety.text}
              headingVariant="p"
              headingClassName="h3"
              asText
              dualColor
              centered
            />
          </HeadingArea>

          <VerticalText
            text="ZALETY"
            right
            css={`
              display: none;

              @media (min-width: 1800px) {
                top: 50px;
                display: block;
                right: 305px;
              }
            `}
          />

          <CardsArea>
            {benefitsItems.map((item, index) => (
              <ProductCard
                key={item.tytul.text}
                image={{
                  alt: item.ikona.alt,
                  image: item.ikona.localFile.childImageSharp.fluid,
                }}
                productName={item.tytul.text}
                css="justify-self: center;"
                data-aos="zoom-in"
                data-aos-delay={index * 100}
              />
            ))}
          </CardsArea>

          <BackgroundImageArea>
            <BackgroundImage
              fluid={data.zdjecie_1_zalety.localFile.childImageSharp.fluid}
              alt={data.zdjecie_1_zalety.alt}
            />
          </BackgroundImageArea>
        </Grid>
      </Container>
    </section>
  );
};

const query = graphql`
  query {
    prismicStronaGlowna {
      data {
        zdjecie_1_zalety {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji_zalety {
          text
        }
        naglowek_sekcji_zalety {
          text
        }
        body_zalety {
          slice_type
          items {
            ikona {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            tytul {
              text
            }
          }
        }
      }
    }
  }
`;

export default SectionBenefits;
